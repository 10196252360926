@import "../../../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";
@import "../../../../../../../../Project/Camfil/code/ui/styles/Camfil/themes/cao/imports";

$name: '.PromoSlider';

#{$name} {
    padding: 2rem;

    @include media-breakpoint-up(md) {
        padding: 4rem;
    }

    &.is-single-md {
    }

    &-carousel {
        touch-action: pan-y;
        user-select: none;
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    &-item {
        width: 100%;
        position: relative;

        @include media-breakpoint-up(md) {
            width: 30%;
        }
    }

    &-slideInner {
        display: flex;
        gap: 2rem;
        align-items: flex-start;
    }

    &-itemLink {
        display: flex;
        border-radius: 3rem 0;
        overflow: hidden;
        //height: 480px;
        //width: 100%;
        aspect-ratio: 3 / 5;

        @include media-breakpoint-up(md) {
            //max-width: 336px;
            //padding-top: calc(4 / 3 * 100%) !important;
        }

        &:hover {
            text-decoration: none;

            #{$name}-itemTop {
                @include media-breakpoint-up(md) {
                    opacity: 1;
                }
            }

            #{$name}-itemImage {
                filter: contrast(200%);
            }

            #{$name}-itemInner::before {
                @include media-breakpoint-up(md) {
                    height: 200%;
                }
            }
        }
    }

    &-itemInner {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 200%;
            background: linear-gradient(0deg, rgba(56,99,118,1) 0%, rgba(56,99,118,0) 100%);
            transition: all ease-in .125s; ///
            @include media-breakpoint-up(md) {
                height: 30%;
            }
        }
    }

    &-itemBgContainer {
    }

    &-itemImage {
        height: 100%;
        width: 100%;
        position: absolute;
        object-fit: cover;
        z-index: -1;
        transition: filter ease-in .125s; ///
    }

    &-itemTop {
        position: relative;
        z-index: 10;
        font-size: 1.8rem;
        line-height: 1;
        padding: 2rem;
        color: $white;
        opacity: 1;
        transition: opacity ease-in .125s; ///
        @include media-breakpoint-up(md) {
            opacity: 0;
            padding: 1.5rem;
        }

        @include media-breakpoint-up(xl) {
            padding: 2rem;
        }
    }

    &-itemBottom {
        position: relative;
        z-index: 10;
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 1;
        padding: 2rem;
        margin-top: auto;
        color: $white;

        @include media-breakpoint-up(md) {
            padding: 1.5rem;
        }

        @include media-breakpoint-up(xl) {
            padding: 2rem;
        }
    }

    .carousel-indicators {
        position: static;
        margin-top: 2rem;
        margin-bottom: 0;
        margin-left: 0;
        justify-content: flex-start;

        li {
            width: 1rem;
            height: 1rem;
            background-color: transparent;
            border: 2px solid $cao-dark-blue;
            border-radius: 50%;

            &.active {
                background-color: $cao-dark-blue;
            }
        }
    }

    .carousel-control {
        &-prev,
        &-next {
            width: auto;
            opacity: 1;

            &-icon {
                height: 2rem;
                width: 2rem;
                background-image: none;
                color: $cao-dark-blue;
            }
        }

        &-prev {
            left: -2rem;

            @include media-breakpoint-up(md) {
                left: -3rem;
            }
        }

        &-next {
            right: -2rem;

            @include media-breakpoint-up(md) {
                right: -3rem;
            }
        }
    }
}
